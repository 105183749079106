import { useState, useCallback } from 'react';

export default function useDoOnce(callback: Function) {
  const [done, setDone] = useState(false);
  return useCallback(() => {
    if (done) return;
    setDone(true);
    callback();
  }, [callback, done]);
}
