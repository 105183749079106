import React from 'react';
import redHeartIcon from '@assets/images/my-favorites-toast-red-heart.svg';
import grayHeartIcon from '@assets/images/my-favorites-toast-gray-heart.svg';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
  saveToFavorites: boolean;
  // eslint-disable-next-line react/require-default-props
  inMobileView?: boolean;
};

export default function FavoritesToastMessage(props: Props) {
  const { saveToFavorites, inMobileView } = props;

  return (
    <div
      className="fixed m-auto left-0 right-0 z-100 flex items-center w-full max-w-xs p-4 text-white bg-black rounded-lg shadow dark:text-gray-400 dark:bg-black"
      role="alert"
      style={{ bottom: inMobileView ? '90px' : '20px' }}
    >
      <Image src={saveToFavorites ? redHeartIcon : grayHeartIcon} alt="heart" />
      <div className="ml-2 text-sm font-normal">
        {saveToFavorites ? 'Saved to your Favorites!' : 'Removed from your Favorites'}
      </div>
      {saveToFavorites && (
        <Link href="/users/my-favorites">
          <a
            style={{ color: 'white' }}
            className="text-12 ml-16 -mx-1.5 -my-1.5 font-rp-pn-semi-bold underline decoration-white"
          >
            View
          </a>
        </Link>
      )}
    </div>
  );
}
