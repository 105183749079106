/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import star from '@components/common/Yotpo/StarIcon.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Swiper as SwiperType, Pagination, Lazy } from 'swiper';
import { getCdnImageUrl } from '@utils/cloudflare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import Image from '@common/Image/ImageFallback';
import Chevron from '@solar/assets/images/directional/chevron';
import Favorite from '@solar/assets/images/action/favorite';
import styles from './HotelCardV2.module.css';

SwiperCore.use([Pagination, Lazy]);

const FormatNumReviews = (reviews: number) => {
  if (reviews > 1000) {
    const str = [Math.floor(reviews / 1000).toString()];
    const remainder = reviews % 1000;
    if (remainder >= 100) {
      str.push(Math.floor(remainder / 100).toString());
    }

    return `${str.join('.')}k`;
  }
  return reviews;
};

type HomePageHotelCardProps = {
  name: string;
  rating: string;
  reviews: number;
  location: string;
  vibe: string;
  categories: Array<string>;
  images: Array<string>;
  showFavorite?: boolean;
  isFavorited?: boolean;
  onFavorite?: React.MouseEventHandler;
  onClick?: React.MouseEventHandler;
};

export default function HomePageHotelCard(p: HomePageHotelCardProps) {
  const {
    name,
    rating,
    reviews,
    location,
    vibe,
    categories,
    images,
    showFavorite,
    isFavorited,
    onFavorite,
    onClick,
  } = p;

  const swiper = useRef<SwiperType>();

  const handlePrevSlide = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (swiper.current) {
      swiper.current.slidePrev();
    }
  };

  const handleNextSlide = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (swiper.current) {
      swiper.current.slideNext();
    }
  };

  return (
    <div className="w-full font-rp-basetica">
      <div className={`${styles.gallery} relative`}>
        <Swiper
          className="h-full"
          modules={[Pagination, Lazy]}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          lazy={{
            loadPrevNext: true,
          }}
          onSwiper={(s) => {
            swiper.current = s;
          }}
          noSwiping
          loop
        >
          {images.map((i) => (
            <SwiperSlide key={i}>
              <Image
                layout="fill"
                src={getCdnImageUrl(500, i, '', 95)}
                blurDataUrl={getCdnImageUrl(37, i, '', 10)}
                alt={name}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {showFavorite && (
          <button
            type="button"
            className="absolute top-2 right-2 z-10 swiper-no-swiping"
            onClick={onFavorite}
          >
            <Favorite isFavorited={!!isFavorited} className="w-22 h-22" />
          </button>
        )}

        {/*-------------------------------------------------------------
        // Slide Navigation --------------------------------------------
        // -------------------------------------------------------------
        */}
        <div
          className={`${styles.navigation} w-full absolute hidden justify-between bottom-2 z-10 px-2`}
        >
          <button
            type="button"
            className="p-6px bg-solar-button-secondary hover:bg-solar-button-secondary-hover border border-solar-primary rounded-solar-sm swiper-no-swiping"
            onClick={handlePrevSlide}
          >
            <Chevron className="w-16px h-16px" direction="left" />
          </button>

          <button
            type="button"
            className="p-6px bg-solar-button-secondary hover:bg-solar-button-secondary-hover border border-solar-primary rounded-solar-sm swiper-no-swiping"
            onClick={handleNextSlide}
          >
            <Chevron className="w-16px h-16px" direction="right" />
          </button>
        </div>
      </div>

      {/*-------------------------------------------------------------
      // Hotel Details -----------------------------------------------
      // -------------------------------------------------------------
      */}
      <button type="button" className="w-full text-left mt-8px text-14" onClick={onClick}>
        <div className="text-solar-primary whitespace-nowrap truncate">{name}</div>
        <div className="flex">
          {rating ? (
            <div className="flex-shrink-0 text-solar-primary flex items-center">
              {rating}
              <span className="flex items-center mx-1">
                <Image src={star} alt="Star Rating Icon" />
              </span>
              <span className="text-solar-secondary">({FormatNumReviews(reviews)})</span>
            </div>
          ) : null}
          <div className="text-solar-secondary flex-grow-0 whitespace-nowrap truncate">
            {rating ? (
              <span className="inline-block px-1 text-solar-inverted-secondary text-13">|</span>
            ) : null}
            {location}
          </div>
          {vibe ? (
            <div className="text-solar-secondary flex-shrink-0">
              <span className="inline-block px-1 text-solar-inverted-secondary text-13">|</span>
              {vibe}
            </div>
          ) : null}
        </div>
        <div className="text-solar-secondary whitespace-nowrap truncate">
          {categories.join(', ')}
        </div>
      </button>
    </div>
  );
}

HomePageHotelCard.defaultProps = {
  showFavorite: false,
  isFavorited: false,
  onFavorite: () => {},
  onClick: () => {},
};
