type ProductTypes = {
  [key: string]: string;
};

type ProductCategoriesMap = {
  [key: string]: string[];
};

const normalizedProductType: ProductTypes = {
  // Product Type (AA): new normalized name (only used in the code)
  Activity: 'activity',
  'Beach Pass': 'beach_pass',
  cabana: 'cabana',
  couples_spa: 'couples_spa',
  'day-bed': 'day_bed',
  day_pass: 'day_pass',
  'Day Room': 'day_room',
  event: 'event',
  facial: 'facial',
  family_pass: 'family_pass',
  fitness_pass: 'fitness_pass',
  food_and_drink: 'food_and_drink',
  'Golf Pass': 'golf_pass',
  'Group Package': 'group_package',
  kids_pass: 'kids_pass',
  Massage: 'massage',
  'Meeting Room': 'meeting_room',
  nails: 'nails',
  parking_pass: 'parking_pass',
  'Pool Chair': 'pool_chair',
  spa_pass: 'spa_pass',
  spa_treatment: 'spa_treatment',
  work_pass: 'work_pass',
};

const normalizedProductTypeName: ProductTypes = {
  // Product Type (AA): new normalized name (only used in the code)
  Activity: 'activity',
  'Beach Pass': 'beach_pass',
  Cabana: 'cabana',
  'Couples Spa': 'couples_spa',
  Daybed: 'day_bed',
  'Day Pass': 'day_pass',
  'Day Room': 'day_room',
  Event: 'event',
  Facial: 'facial',
  'Family Pass': 'family_pass',
  'Fitness Pass': 'fitness_pass',
  'Food & Drink': 'food_and_drink',
  Golf: 'golf_pass',
  'Group Package': 'group_package',
  'Kids Club': 'kids_pass',
  Massage: 'massage',
  'Meeting Room': 'meeting_room',
  Nails: 'nails',
  Parking: 'parking_pass',
  'Pool Chair': 'pool_chair',
  'Spa Pass': 'spa_pass',
  'Spa Treatment': 'spa_treatment',
  'Work Pass': 'work_pass',
};

const productCategoriesMap: ProductCategoriesMap = {
  Pool: ['cabana', 'day_pass', 'day_bed', 'family_pass', 'kids_pass', 'pool_chair'],
  Beach: ['beach_pass'],
  Spa: ['couples_spa', 'facial', 'massage', 'nails', 'spa_pass', 'spa_treatment'],
  'Events & Activities': ['fitness_pass', 'golf_pass', 'activity', 'group_package', 'event'],
  'Work Space': ['work_pass', 'meeting_room'],
  'Day Rooms': ['day_room'],
};

const spaBannerProducts = ['couples_spa', 'facial', 'massage', 'nails', 'spa_treatment'];

export {
  productCategoriesMap,
  normalizedProductType,
  normalizedProductTypeName,
  spaBannerProducts,
};
