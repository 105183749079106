import { ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES } from '@constants/amplitudeEvents';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import HotelApi from '@customTypes/hotel-api';
import HotelDetails from '@customTypes/hotel-details';
import { useEvents } from '@events/EventsProvider';
import { accessHotelMetadataUsingHotelIds, addFavorites, removeFavorites } from '@utils/services';

export default function useFavorites() {
  const { track } = useEvents();

  /**
   * Tracks an event with event name and hotel details, including optional metadata retrieval.
   *
   * @param {string} eventName - The name of the event to track.
   * @param {HotelDetails | HotelApi} hotel - The hotel details object containing.
   * @returns {Promise<void>} - A promise that resolves when the tracking is complete.
   */
  const trackEvent = async (eventName: string, hotel: HotelDetails | HotelApi) => {
    let hotelMetadata;
    const cityName = (hotel as HotelApi).cityName || (hotel as HotelDetails).city_name;

    const cityId = (hotel as HotelApi).cityId || (hotel as HotelDetails).city_id;

    const hotelStar = (hotel as HotelApi).hotelStar || (hotel as HotelDetails).star_classification;

    // HotelDetails does not contain favorites_count,
    // it needs to be fetched from a different endpoint.
    if (typeof hotel === 'object' && !('favorites_count' in hotel)) {
      const hotelMetadataResponse = await accessHotelMetadataUsingHotelIds([
        (hotel as HotelDetails).id,
      ]);

      hotelMetadata = hotelMetadataResponse[(hotel as HotelDetails).id];
    }

    track(eventName, {
      hotel_city_id: cityId,
      hotel_city_name: cityName,
      hotel_favorites_count:
        'favorites_count' in hotel
          ? (hotel as HotelApi).favoritesCount
          : hotelMetadata.favorites_count,
      hotel_id: +hotel.id,
      hotel_name: hotel.name,
      hotel_star_classification: hotelStar,
    });
  };

  const removeItemFromArray = (arr: any, value: string) => {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const updateUserCookieFavorites = (cookies: any, setCookie: any, userFavoritesIds: any) => {
    if (cookies.userInformation?.email) {
      const updatedUserFavorites = cookies.userInformation;
      updatedUserFavorites.favorites = userFavoritesIds;
      setCookie('userInformation', updatedUserFavorites, { path: '/' });
    }
  };

  const addHotelToFavorites = (
    cookies: any,
    setCookie: any,
    hotel: HotelDetails | HotelApi,
    setShowFavoriteToastMessage: any,
    userFavoritesIds: any,
    setSaveToFavorites: any,
    setItem: any,
    getItem: any,
    setUserFavoritesIds: any,
  ) => {
    setShowFavoriteToastMessage(false);
    const tempUserFav = userFavoritesIds || [];
    const userLocalStorageFavorites = getItem(sessionStorageKeys.USER_SESSION_FAVORITES);
    const updateLocalStorageFavorites: any = {};
    if (cookies.userInformation?.email) {
      if (!tempUserFav.includes(hotel.id.toString())) {
        setSaveToFavorites(true);
        addFavorites(cookies.userInformation, hotel.id.toString());

        trackEvent(ADD_TO_FAVORITES, hotel);

        setUserFavoritesIds(tempUserFav.concat(hotel.id.toString()));
        updateUserCookieFavorites(cookies, setCookie, tempUserFav.concat(hotel.id.toString()));
      } else {
        setSaveToFavorites(false);
        removeFavorites(cookies.userInformation, hotel.id);

        trackEvent(REMOVE_FROM_FAVORITES, hotel);

        const finalFavorites = removeItemFromArray(tempUserFav, hotel.id.toString());
        setUserFavoritesIds(finalFavorites);
        updateUserCookieFavorites(cookies, setCookie, finalFavorites);
        if (userLocalStorageFavorites && userLocalStorageFavorites !== 'null') {
          const { favorites } = JSON.parse(userLocalStorageFavorites);
          if (favorites.includes(hotel.id.toString())) {
            const finalizedFavorites = removeItemFromArray(favorites, hotel.id.toString());
            updateLocalStorageFavorites.favorites = favorites;
            updateLocalStorageFavorites.favorites = finalizedFavorites;
            setItem(
              sessionStorageKeys.USER_SESSION_FAVORITES,
              finalizedFavorites.length > 0 ? JSON.stringify(updateLocalStorageFavorites) : null,
            );
          }
        }
      }
    } else if (!userLocalStorageFavorites || userLocalStorageFavorites === 'null') {
      const favorites = [hotel.id.toString()];
      updateLocalStorageFavorites.favorites = favorites;
      setSaveToFavorites(true);
      setItem(
        sessionStorageKeys.USER_SESSION_FAVORITES,
        JSON.stringify(updateLocalStorageFavorites),
      );
      setUserFavoritesIds(favorites);

      trackEvent(ADD_TO_FAVORITES, hotel);
    } else if (JSON.parse(userLocalStorageFavorites)) {
      const { favorites } = JSON.parse(userLocalStorageFavorites);
      if (favorites.includes(hotel.id.toString())) {
        setSaveToFavorites(false);
        const finalizedFavorites = removeItemFromArray(favorites, hotel.id.toString());
        updateLocalStorageFavorites.favorites = finalizedFavorites;
        setItem(
          sessionStorageKeys.USER_SESSION_FAVORITES,
          finalizedFavorites.length > 0 ? JSON.stringify(updateLocalStorageFavorites) : null,
        );
        setUserFavoritesIds(finalizedFavorites);

        trackEvent(REMOVE_FROM_FAVORITES, hotel);
      } else {
        const combineFavorites = JSON.parse(userLocalStorageFavorites).favorites;
        combineFavorites.push(hotel.id.toString());
        updateLocalStorageFavorites.favorites = combineFavorites;
        setSaveToFavorites(true);
        setItem(
          sessionStorageKeys.USER_SESSION_FAVORITES,
          JSON.stringify(updateLocalStorageFavorites),
        );
        setUserFavoritesIds(combineFavorites);

        trackEvent(ADD_TO_FAVORITES, hotel);
      }
    }
    setShowFavoriteToastMessage(true);
    setTimeout(() => {
      setShowFavoriteToastMessage(false);
    }, 5000);
  };

  return { addHotelToFavorites };
}
