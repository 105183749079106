import { useRef, useEffect, useState, RefObject } from 'react';

export default function useOnScreen(offset = 0): [boolean, RefObject<any>] {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = useRef<any>(null);

  const onScroll = () => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const { top } = currentElement.current.getBoundingClientRect();
    setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });

  return [isVisible, currentElement];
}
