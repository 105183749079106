import React from 'react';

type Props = {
  className?: string;
  isFavorited: boolean;
};

export default function FavoriteIcon({ isFavorited, className }: Props) {
  let fill = '#22272A';
  if (isFavorited) {
    fill = '#E12F31';
  }
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.60033 11.2495L2.48693 5.91636C2.03753 5.46731 1.74091 4.88816 1.63915 4.26106C1.5374 3.63396 1.63566 2.99074 1.92002 2.42262C2.13444 1.9939 2.44766 1.62227 2.83387 1.33834C3.22009 1.05441 3.66824 0.866309 4.1414 0.78954C4.61457 0.712771 5.0992 0.74953 5.55537 0.896787C6.01154 1.04404 6.4262 1.29759 6.76517 1.63652L7.60033 2.47116L8.4355 1.63652C8.77447 1.29759 9.18913 1.04404 9.6453 0.896787C10.1015 0.74953 10.5861 0.712771 11.0593 0.78954C11.5324 0.866309 11.9806 1.05441 12.3668 1.33834C12.753 1.62227 13.0662 1.9939 13.2806 2.42262C13.5646 2.99052 13.6627 3.63336 13.5611 4.2601C13.4594 4.88684 13.1632 5.46572 12.7143 5.91476L7.60033 11.2495Z"
        fill={fill}
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

FavoriteIcon.defaultProps = {
  className: '',
};
